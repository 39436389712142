<!-- 智慧助理 -->
<template>
  <div>
    <!-- 浮動按鈕 -->
    <v-btn class="floating-btn" :style="{ backgroundColor: dialogIcon ? themeColor : '#0288d1' }" dark fab @click="showDialog">
      <v-img v-if="dialogIcon" :src="dialogIcon" max-width="40" class="mr-2" />
      <v-icon v-else>mdi-chat-question</v-icon>
    </v-btn>

    <!-- 聊天窗口 -->
    <v-dialog v-if="dialog" v-model="chatDialog" :width="getDialogWidth()" content-class="chat-dialog rounded-xl">
      <v-card>
        <v-card-title class="white--text" :style="{ backgroundColor: themeColor }">
          <v-img v-if="dialogIcon" :src="dialogIcon" max-width="40" class="mr-2" />
          <v-img v-else src="@/assets/favicon_white.png" max-width="40" class="mr-2" />
          {{ dialogTitle }}
        </v-card-title>
        <v-progress-linear :indeterminate="loading" color="cyan"></v-progress-linear>
        <!-- 聊天內容 -->
        <v-container class="chat-container" :style="{ maxHeight: getContainerMaxHeight() }" ref="chatContainer">
          <v-list>
            <div v-for="(item, index) in msgData" :key="index">
              <!-- 通知訊息 -->
              <v-list-item v-if="item.divider">
                <v-divider />
                <span class="ma-2" style="font-size: 13px; color: #bdbdbd">{{ item.msg }}</span>
                <v-divider />
              </v-list-item>
              <!-- 對話訊息 -->
              <v-list-item v-else>
                <!-- 機器人頭像 -->
                <v-list-item-icon v-if="item.bot">
                  <v-avatar color="cyan darken-1" size="40">
                    <v-img v-if="item.icon" :src="item.icon" />
                    <v-img v-else contain src="@/assets/favicon_white.png" />
                  </v-avatar>
                </v-list-item-icon>
                <!-- 訊息內容 -->
                <v-list-item-content>
                  <v-row>
                    <v-col :class="{ 'text-right': !item.bot }">
                      <v-list-item-subtitle
                        style="color: black; font-size: 16px"
                        class="mt-1 pa-3 text-left chat-message rounded-b-lg"
                        :class="item.bot ? 'rounded-tr-lg' : 'rounded-tl-lg'"
                        :style="{ backgroundColor: item.bot ? 'aquamarine' : '#E0F7FA' }"
                      >
                        {{ item.msg }}
                      </v-list-item-subtitle>
                    </v-col>
                  </v-row>
                </v-list-item-content>
                <!-- 用戶頭像 -->
                <!-- <v-list-item-icon v-if="!item.bot">
                  <v-avatar color="light-blue lighten-3" size="40">
                    <v-icon dark> mdi-account </v-icon>
                  </v-avatar>
                </v-list-item-icon> -->
              </v-list-item>
            </div>
          </v-list>
        </v-container>
        <!-- 預設問題選擇 -->
        <v-chip-group>
          <v-chip v-for="(str, index) in questionList" :key="index" :disabled="loading" outlined @click="sendMsg(str)">
            {{ str }}
          </v-chip>
        </v-chip-group>
        <!-- 輸入框 -->
        <v-divider />
        <v-container>
          <v-row no-gutters>
            <v-col>
              <v-textarea
                :disabled="loading"
                ref="chatEdit"
                class="chat-edit no-underline"
                v-model="editValue"
                label="輸入訊息"
                auto-grow
                rows="1"
                @keydown.enter.prevent="onSendMsg"
              ></v-textarea>
            </v-col>
            <v-col cols="1" class="pt-2">
              <!-- 發送紐 -->
              <v-btn v-if="editValue" :disabled="loading" icon @click="onSendMsg">
                <v-icon color="light-blue darken-1">mdi-send</v-icon>
              </v-btn>
              <!-- 麥克風 -->
              <v-btn v-else icon :disabled="loading" @click="onMic">
                <v-icon>{{ isVoice ? "mdi-close" : "mdi-microphone-outline" }}</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <!-- 語音視窗 -->
    <voice-dialog :show.sync="isVoice" @on-blob="onVoiceBlob" />
  </div>
</template>

<script>
import VoiceDialog from "@/components/VoiceDialog.vue";
import VoiceBotServer from "@/lib/voice-bot-server";

/** 訊息資料格式
 * @typedef {Object} MsgData
 * @property {string} msg - 訊息
 * @property {Boolean} divider - 是否為系統訊息
 * @property {Boolean} bot - 是否為機器人
 * @property {Function} fun - 要執行的動作
 */

export default {
  components: { VoiceDialog },
  data() {
    return {
      chatDialog: false,
      /** 用於重整 DOM (防切頁面時顯示異常) */
      dialog: false,
      dialogTitle: "樂樂社區管家",
      dialogIcon: "",
      themeColor: "#00ACC1",

      /** 會話 ID */
      sessionId: "",
      /** 對話訊息 @type {MsgData[]} */
      msgData: [],

      editValue: "",
      loading: false,
      /** 預設問題選項 */
      questionList: ["九月份舉辦了幾次下午茶會", "今天台北的天氣好嗎", "社區遭小偷的處理流程", "我想找鎖匠"],
      /** 是否啟用語音 */
      isVoice: false,
      voiceBot: new VoiceBotServer(),
    };
  },
  methods: {
    /** 獲取內容最高高度 */
    getContainerMaxHeight() {
      // 畫面高度
      let wh = this.$store.getters.getWindowHeight;
      // 是否為音箱
      let isSpeaker = this.$store.getters.isSpeaker;
      // 高度比率
      let ratio = isSpeaker ? 0.35 : 0.55;
      return `${wh * ratio}px`;
    },

    /** 獲取視窗寬度 */
    getDialogWidth() {
      let isSpeaker = this.$store.getters.isSpeaker;
      return isSpeaker ? 400 : 600;
    },

    /** 開場白 */
    opening() {
      if (this.msgData.length) return;
      this.setBotMsg("你好! 我是樂樂管家,\n請問有需要什麼幫助嗎?");
    },

    /** 顯示視窗 */
    showDialog() {
      this.chatDialog = !this.chatDialog;
      this.opening();
    },

    /** 發送消息
     * @param {string} msg 訊息內容
     */
    sendMsg(msg) {
      this.msgData.push({ msg });
      this.sendToBot(msg);
    },

    /** [事件] 監聽輸入框 Enter 事件
     * @param {MouseEvent} e 點擊事件
     */
    onSendMsg(e) {
      if (this.loading || !this.editValue) return;
      // 當 Shift + Enter 時換行
      if (e.shiftKey) {
        this.editValue += "\n";
      } else {
        this.sendMsg(this.editValue);
        this.editValue = null;
      }
    },

    /** [事件] 使用麥克風輸入 */
    onMic() {
      this.isVoice = !this.isVoice;
      this.isShowVoice = !this.isShowVoice;
    },

    /** [事件] 監聽語音輸入
     * @param {Blob} blob 聲音數據
     */
    async onVoiceBlob(blob) {
      if (blob) {
        this.loading = true;
        let msg = await this.voiceBot.speechToText(blob).finally(() => (this.loading = false));
        this.editValue = msg;
      }
    },

    /** 發訊息給機器人
     * @param {string} msg 訊息內容
     */
    async sendToBot(msg) {
      if (this.loading || !msg || this.sendToBot_test(msg)) return;
      this.loading = true;
      let res = await this.$api.sendMsgToBot(msg, this.sessionId).finally(() => (this.loading = false));
      this.sessionId = res.id;
      this.msgData.push({ msg: res.output, bot: true });
      this.$refs.chatEdit.focus();
    },

    /** 模擬機器人訊息
     * @param  {...any } msgData 訊息(可多個)
     */
    setBotMsg(...msgData) {
      this.loading = true;
      if (this.intervalId) return;
      this.intervalId = setInterval(() => {
        if (msgData.length) {
          let data = msgData.shift();
          if (typeof data == "string") data = { msg: data };
          data.bot = true;
          this.msgData.push(data);
          data.action?.call();
          // 結束訊息發送
          if (!msgData.length) {
            clearInterval(this.intervalId);
            this.intervalId = null;
            this.loading = false;
          }
        }
      }, 1300);
      return true;
    },

    // ===== 測試用方法 =====

    /** 測試用機器人
     * @param {string} msg 訊息內容
     * @returns {bool} 觸發狀態
     */
    sendToBot_test(msg) {
      let botArr = [this.bot_Bangbu, this.bot_Sam, this.bot_Nicole, this.bot_Test, this.bot_Elysia];
      return botArr.some((e) => e(msg));
    },

    /**
     * 測試訊息
     * @param {string} msg
     */
    bot_Test(msg) {
      let items = [
        {
          k: "TEST",
          v: `
          第⼀條、⽬的： 
          為健全本社區公共事務管理之財務結構，特依本社區住⼾規約第⼗條、第⼗⼀條、第⼗三條及第⼗四條規定制定本管理辦法。

          第⼆條、管理機構： 
          本辦法之管理機構為本社區管理委員會（以下簡稱管委會）。 

          第三條、事務範圍： 
          ⼀、會計事務：會計收⽀報告、會計帳務整理、預算編制執⾏。 
          ⼆、出納事務：管理費收繳保管、⽀付及催繳。前項事務範圍除管理費⽤收繳保管、⽀付之業務外，管理委員會得以書⾯授權管理服務⼈執⾏之。 

          第四條、會計收⽀報告： 
          ⼀、 物業服務中⼼應於次⽉五⽇前，將上⼀⽉之財務報表呈報財務委員審查，並經監察委員及主任委員審議通過後，公佈之。 
          ⼆、 前項會計收⽀報告應包括收⽀統計表、收⽀明細表及專⼾存款餘額記錄。 

          第五條、會計帳務整理： 
          管委會應設立本社區專⽤之帳冊，並按帳務規定記錄每⽇會計發⽣事項之單據、憑證、傳票表冊，以供製作會計收⽀報告。前項單據、憑證、傳票表冊應妥善整理，由監察委員不定期稽核。 
          
          第六條、預算書編製執⾏： 
          管委員會⾃接管事起算⽇起，每屆滿⼀年前兩個⽉內，應編製下⼀年度之管理費⽤預算書與上⼀年度之收⽀算報告書，提交區分所有權⼈會議審議通過後，公佈施⾏之。
          `,
        },
        {
          k: ["清除", "clear"],
          a: () => (this.msgData.length = 0),
        },
      ];

      let item = items.find((e) => {
        let isStr = typeof e.k == "string";
        return isStr ? msg.includes(e.k) : e.k.some((s) => msg.includes(s));
      });

      return item?.a ? !item.a() : item ? this.setBotMsg(item.v) : null;
    },

    /** 愛莉希雅(機器人)
     * @param {string} msg 訊息內容
     * @returns {bool | undefined} 觸發狀態
     */
    bot_Elysia(msg) {
      if (!msg.includes("整活")) return;

      // 角色頭像
      let iconUrl = "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSV0oLn7uIkTEXH4r_895yp0HkP_KeUZHpGeSpE2NdZ2RZcN_gK9wLIUq0jMd1nD022xL4&usqp=CAU";

      return this.setBotMsg(
        { msg: "好的, 沒問題!", icon: iconUrl },
        { msg: "[愛莉希雅]開始了奇怪的表演", divider: true },
        { msg: "嘿! 哈!", icon: iconUrl },
        { msg: "唉! 呀!", icon: iconUrl },
        { msg: "[愛莉希雅]結束了奇怪的表演", divider: true },
      );
    },

    /** 邦布(機器人)
     * @param {string} msg 訊息內容
     * @returns {bool | undefined} 觸發狀態
     */
    bot_Bangbu(msg) {
      if (!msg.includes("嗯呢")) return;

      // 角色頭像
      let iconUrl = "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq7s4NZiQF-Guu_eLsUkffUzSGilgHPdVOIA&s";

      // 表示啟用角色
      if (!this.bangbu) {
        this.bangbu = true;
        this.msgData.push({ msg: "好友[伊埃斯]已進入聊天室", divider: true });
      }

      // 邦布對話資料(k:關鍵字, v:回應訊息)
      let chatData = [
        { k: "你好", v: "嗯呢!\n(你好呀!)" },
        { k: "你是誰", v: "嗯呢,嗯呢嗯呢\n(你好呀,我叫伊埃斯)" },
        { k: "伊埃斯", v: "嗯呢!\n(我在!)" },
        { k: "玩", v: "嗯呢嗯呢!\n(一起玩! 一起玩!)" },
      ];

      return this.setBotMsg({
        icon: iconUrl,
        msg: chatData.find((e) => msg.includes(e.k))?.v || "嗯呢嗯呢?\n(你是在叫我嗎?)",
      });
    },

    /** 薩姆(機器人)
     * @param {string} msg 訊息內容
     * @returns {bool | undefined} 觸發狀態
     */
    bot_Sam(msg) {
      let iconUrl = "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQEBtxF00a3bYfevj2tgJizbngPY0cjaGNpKA&s";
      let items = [
        { k: "大海", v: "我將, 點燃大海!\n(噔~ 噔↘噔↗噔↘噔↗噔↗ 咚咚 噔噔~)" },
        { k: "我將", v: "點燃大海!\n(噔~ 噔↘噔↗噔↘噔↗噔↗ 咚咚 噔噔~)" },
      ];

      let item = items.find((e) => msg.includes(e.k));
      return item ? this.setBotMsg({ icon: iconUrl, msg: item.v }) : null;
    },

    /** 妮可(機器人)
     * @param {string} msg 訊息內容
     * @returns {bool | undefined} 觸發狀態
     */
    bot_Nicole(msg) {
      if (!(this.nicole || msg?.includes("妮可"))) return;

      // 角色頭像
      let iconUrl = "https://patchwiki.biligame.com/images/zzz/a/ae/2ve5rqnangkt1i8935q3znnh2dakzh3.png";

      // 表示角色進入
      if (!this.nicole) {
        this.nicole = true;
        this.dialogTitle_old = this.dialogTitle;
        this.themeColor_old = this.themeColor;
        return this.setBotMsg(
          ...[
            { msg: "好友[妮可]已進入聊天室", divider: true },
            {
              msg: "好友[妮可]已修改聊天室名稱",
              divider: true,
              action: () => {
                let icon = "https://patchwiki.biligame.com/images/zzz/b/bc/pgjegfdy49nom444tzodb36vbg957lb.png";
                this.setDialogTitle("萬事屋『狡兔屋』✨", icon);
                this.themeColor = "#37474F";
              },
            },
            { msg: "萬能事務所, 狡兔屋\n在此為您服務!🎉", icon: iconUrl },
          ],
        );
        // 表示角色離開
      } else if (msg?.includes("沒事") || msg?.includes("離開")) {
        return this.setBotMsg(
          ...[
            { msg: "既然沒事, 那我就先走了", icon: iconUrl },
            {
              msg: "好友[妮可]已離開聊天室",
              divider: true,
              action: () => {
                this.nicole = false;
                this.setDialogTitle(this.dialogTitle_old);
                this.themeColor = this.themeColor_old;
              },
            },
          ],
        );
      }

      /** 訊息資料格式
       * @typedef {Object} ChatData
       * @property {string|string[]} k - 關鍵字
       * @property {string|string[]} v - 回應訊息
       * @property {Boolean} a - 關鍵字需全符合(用於複數關鍵字時)
       */

      /** @type {ChatData} */
      let chatData = [
        { k: "粉絲", v: "喵喵喵!?\n你突然間在說甚麼呢!\n(臉紅)" },
        { k: "在嗎在嗎在嗎", v: "在呢在呢在呢!" },
        { k: "在嗎", v: "在呢" },
        { k: "丁尼", v: ["什麼!", "在哪裡!!?", "都是我的!!"] },
        { k: ["你", "丁尼", "我"], v: ["想從我手上拿走丁尼?", "門都沒有!"], a: true },
        { k: ["版號", "版本"], v: "當前版號為 v1.0.0" },
        { k: ["我", "哭"], v: ["啊...你沒事吧?\n(慌張)", "別...別傷心,\n我相信一切都會好起來的!"], a: true },
        { k: [..."我是誰"], v: ["有錢的大冤...", "咳咳..\n我是說有錢的大客戶"], a: true },
      ];

      // 排序，複數關鍵字在前
      chatData.sort((a, b) => {
        let type = (e) => typeof e.k != "string";
        let ex = type(a) && type(b);
        return ex ? b.k.length - a.k.length : type(a) ? -1 : 0;
      });

      // 判斷動作
      let chat = chatData.find((e) => {
        let b = typeof e.k == "string";
        let check = e.a ? "every" : "some";
        return b ? msg?.includes(e.k) : e.k[check]((s) => msg?.includes(s));
      });

      if (chat) {
        if (typeof chat.v == "string") {
          return this.setBotMsg({ msg: chat.v, icon: iconUrl });
        } else {
          return this.setBotMsg(...chat.v.map((msg) => ({ msg, icon: iconUrl })));
        }
      }

      // 無關鍵事狀態下
      this.nicole_err ??= 0;
      this.nicole_err++;

      switch (this.nicole_err) {
        default:
          return this.setBotMsg({ msg: "哈, 有事?", icon: iconUrl });
        case 3:
          return this.setBotMsg(
            ...[
              { msg: "那個...", icon: iconUrl },
              { msg: "如果沒事了話, 我就先走了", icon: iconUrl },
              { msg: "我的時間可是很寶貴的", icon: iconUrl },
              {
                msg: "好友[妮可]已離開了聊天室",
                divider: true,
                action: () => {
                  this.nicole = false;
                  this.nicole_err = null;
                  this.setDialogTitle(this.dialogTitle_old);
                  this.themeColor = this.themeColor_old;
                },
              },
            ],
          );
      }
    },

    /** 修改聊天室標題
     * @param {string} title 標題名稱
     * @param {string | null} iconUrl 標題圖示
     */
    setDialogTitle(title, iconUrl) {
      this.dialogTitle = "";
      this.dialogIcon = iconUrl;
      this.botActionId = setInterval(() => {
        if (this.dialogTitle.length != title.length) {
          this.dialogTitle += title[this.dialogTitle.length];
        } else {
          clearInterval(this.botActionId);
        }
      }, 100);
    },
  },
  watch: {
    msgData() {
      // 更新滾動位置，使其置底
      this.$nextTick(() => {
        const container = this.$refs.chatContainer;
        container.scrollTop = container.scrollHeight;
      });
    },
    editValue() {
      // 更新滾動位置，使其置底
      this.$nextTick(() => {
        const input = this.$refs.chatEdit.$refs.input;
        input.scrollTop = input.scrollHeight;
      });
    },
    chatDialog() {
      // 用於重整 Dialog DOM，且確保打開時訊息置底
      if (this.chatDialog) {
        this.dialog = true;
        this.$nextTick(() => {
          const container = this.$refs.chatContainer;
          container.scrollTop = container.scrollHeight;
        });
      } else {
        setTimeout(() => (this.dialog = false), 100);
      }
    },
  },
};
</script>

<style>
.floating-btn {
  position: fixed;
  bottom: 16px;
  right: 16px;
  z-index: 1000;
}

.chat-dialog {
  position: fixed !important;
  bottom: 80px;
  right: 16px;
  margin: 0;
  max-height: calc(100% - 80px);
  overflow: hidden;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
}

.chat-container {
  max-height: calc(100% - 200px);
  min-height: 100px;
  /* height: */
  overflow-y: auto; /* 啟用滾動 */
  scrollbar-width: none; /* 隱藏滾動條 - Firefox */
  -ms-overflow-style: none; /* 隱藏滾動條 - IE 10+ */
}

.chat-message {
  white-space: pre-line;
  word-wrap: break-word;
  word-break: break-word;
  display: inline-block;
}

.chat-edit {
  max-height: 200px;
  overflow-y: auto; /* 啟用滾動 */
  scrollbar-width: none; /* 隱藏滾動條 - Firefox */
  -ms-overflow-style: none; /* 隱藏滾動條 - IE 10+ */
}

.no-underline .v-input__control .v-input__slot:before,
.no-underline .v-input__control .v-input__slot:after {
  border: none !important;
}
</style>

const show = () => console.log(msg, ...styleList);
const print = (str, style) => (msg += `%c${str}`) && styleList.push(style);
const println = (str, style) => print(`${str}\n`, style);

let msg = "";
let styleList = [];

print(" /\\", "color: #FFA726");
println(
  `_/\\
( o.o ) 
 > ^ <
`,
);

println(
  `
               01010101#                       
              010101010101                     
              010101010101#                    
             01  0101010101#                   
            01#  010101 0101#                  
            01# 010101#   0101                 
           01#  0101010101 0101                
          0101  0101010101# 0101               
        0101#   0101010101#  0101#             
       010101   01# 01010101   0101#           
       0101#   01#   01010101   010101         
      010101   01#  0101010101#   010101       
     010101   0101 01010101010101  010101      
    010101#  01010101010101010101# 010101#     
    010101#  010101010101010101010101010101    
   010101#  010101 0101010101010101# 010101#   
   010101#  010101 010101 01010101#   010101   
   010101#    01  010101   010101     010101   
   010101#        010101    0101#     0101#    
    010101        0101#     0101#     0101     
     0101#        0101      0101#     01#      
      0101#      ;01#        01#      #        
        01       0101        0101                
  `,
  "color: #007979",
);

println(
  `
  ╔───────────────────────────────────────────────────────────────────────────────╗
  │  __        __   _                            _          _         _        _  │
  │  \\ \\      / /__| | ___ ___  _ __ ___   ___  | |_ ___   | |    ___| | ___  | | │
  │   \\ \\ /\\ / / _ \\ |/ __/ _ \\| '_ \` _ \\ / _ \\ | __/ _ \\  | |   / _ \\ |/ _ \\ | | │
  │    \\ V  V /  __/ | (_| (_) | | | | | |  __/ | || (_) | | |__|  __/ |  __/ |_| │
  │     \\_/\\_/ \\___|_|\\___\\___/|_| |_| |_|\\___|  \\__\\___/  |_____\\___|_|\\___| (_) │
  │                                                                               │
  ╚───────────────────────────────────────────────────────────────────────────────╝
  `,
);

println("歡迎使用樂樂社區物管系統🎉\n", "font-weight: bold; font-size: 20px;");
println("社區官網:", "font-weight: bold;");
println("https://lelelink.com.tw/\n");

show();
